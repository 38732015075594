<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5>
                <br>
				<CRow class="mb-2">
                    
                    <CCol lg="12">
                        <div class="form-group"> 
                            <label class="mb-1">Status</label>
                            <v-select 
                            label="setting_name" 
                            :options="[
                                {
                                setting_name: 'All',
                                id: null
                                },
                                {
                                setting_name: 'Active',
                                id: 'ACTIVE'
                                },
                                {
                                setting_name: 'Inactive',
                                id: 'INACTIVE'
                                }, 
                                {
                                setting_name: 'Incoming',
                                id: 'INCOMING'
                                }, 
                                {
                                setting_name: 'Archive/Sold',
                                id: 'ARCHIVE'
                                },
                                {
                                setting_name: 'In-shop',
                                id: 'IN-SHOP'
                                },
                                {
                                setting_name: 'Out of Service',
                                id: 'OUT OF SERVICE'
                                },
                            ]"
                            :reduce="item => item.id"
                            v-model="filter.status"
                            placeholder="-Select-" 
                            >
                            <template #search="{attributes, events}">
                                <input
                                class="vs__search"
                                :required="!filter.status"
                                v-bind="attributes"
                                v-on="events"
                                />
                            </template>
                            </v-select>
                        </div>
                    </CCol>
                    <CCol lg="12">
						<label class="mb-1">Plate No.</label>
						<CInput v-model="filter.plate_no"/>
					</CCol>
                    <CCol lg="12">
						<label class="mb-1">Asset No.</label>
						<CInput v-model="filter.asset_no"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Vehicle Type</label>
						<v-select label="setting_name" :options="vehicle_list.data" :reduce="item => item.id" v-model="filter.vehicle_type_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Depot</label>
						<v-select label="setting_name" :options="depot_list.data" :reduce="item => item.id" v-model="filter.depot_id" placeholder="-Select-"></v-select>
					</CCol>
                  
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Company Owned</label>
						<v-select label="label" :options="company_owned_list" :reduce="item => item.id" v-model="filter.company_owned" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Subcon</label>
                        <v-select label="vendor_name" :options="vendor_list.data" :reduce="item => item.id" v-model="filter.vendor_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3" v-if="!is_trucking()">
						<label class="mb-1">Project/Dept</label>
                        <v-select label="customer_name" :options="customer_list.data" :reduce="item => item.id" v-model="filter.project_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
                         <h6 class="text-danger"> {{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <CButton class="float-right" size="sm" color="info" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  
import Datepicker from 'vuejs-datepicker';
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect, Datepicker },
    mounted() {
       
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
                plate_no: "",
				asset_no: "",
				maker_model_id: "",
				vehicle_type_id: "",
				year: "",
				location: "",
				project_id: "",
				company_owned : "",
				vendor_id: "",
				depot_id: '',
                status: null
			},
            depot_list:[],
            customer_list:[],
            vendor_list:[],
            vehicle_list:[],
            company_owned_list:[
                { label: 'Yes', id: 1 }, 
                { label: 'No', id: 0 },
            ],
            currentPage:1,
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            this.report_downloaded = false;
            if(this.dataValidate()){
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                
                // await axios.get(`${config.api_path}/reports/booking`, {
                //     params: {
                //        company_id:config.global_user_data.company_id,
                //         customer_id:this.filter.customer_id ?? "",
                //         plate_number:this.filter.plate_number ?? "",
                //         depot_id:this.filter.depot_id ?? "",
                //         destination_id:this.filter.destination_id ?? "",
                //         booking_id:this.filter.booking_id ?? "",
                //         commodity_id:this.filter.commodity_id ?? "",
                //         asset_type:this.asset_type ?? "",
                //         booking_no:this.booking_no ?? "",
                //         booking_status:this.booking_status ?? "",
                //         trucker_id:this.filter.trucker_id ?? "",
                //         origin_id:this.filter.origin_id ?? "",
                //         file_type:this.filter.file_type ?? "",
                //         client_ref_no:this.client_ref_no ?? "",
                //         date_from:date_from ?? "",
                //         date_to:date_to ?? ""
                //     }
                // })
                const vehicle_type_id = this.filter.vehicle_type_id == 'all' ? null : this.filter.vehicle_type_id;
                await axios.post(`${config.api_path}/reports/vehicle`, {
                    
                    company_id:config.global_user_data.company_id,
                    project_id:this.filter.project_id,
                    depot_id:this.filter.depot_id,
                    vehicle_type_id,
                    vendor_id:this.filter.vendor_id ,
                    plate_no:this.filter.plate_no,
                    asset_no:this.filter.asset_no,
                    company_owned:this.filter.company_owned,
                    status: this.filter.status
            })
                   
                .then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.error_msg = '';
                })
                .catch(err => {
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = 'Please select a filter before downloading the report.';
            }
        },

        getVendor(){
            axios.get(config.api_path+'/reference/vendors-list')
            .then(response => {
                this.vendor_list = response.data;
            });
        },
        getCustomer() {
            axios.get(config.api_path + '/reference/customer-list', {
                params: {show_all: true}
            }).then(response => {
                this.customer_list = response.data;
            });
        },
        getDepot(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'depot'}
             }) .then(response => {
                this.depot_list = response.data;
            });
        },
        
        getVehicle(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'vehicle_type'}
             }) .then(response => {
            this.vehicle_list = response.data; 
            this.vehicle_list.data.unshift({
                id: "all",
                setting_name: "All"
            })
            })

            },
    
        clear(){
            this.filter = {
                plate_no: "",
				asset_no: "",
				maker_model_id: "",
				vehicle_type_id: "",
				year: "",
				location: "",
				project_id: "",
				company_owned : "",
				vendor_id: "",
				depot_id: ''
			}

        },
        dataValidate(){
            return true;

            if(
                this.filter.project_id || 
                this.filter.depot_id || 
                this.filter.vehicle_type_id ||
                // this.filter.plate_no ||
                // this.filter.asset_no ||
                this.filter.vendor_id ||
                this.filter.company_owned
            ){
                return true;
            }
            else{
                return false;
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;
            

            if(this.processing || this.ready_download)
                return;
            
            if(!this.customer_list?.data?.length)
                this.getCustomer();
            if(!this.depot_list?.data?.length)
                this.getDepot();
            if(!this.vendor_list?.data?.length)
                this.getVendor();
            if(!this.vehicle_list?.data?.length)
                this.getVehicle();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>
